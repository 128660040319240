import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nested"]

  removeAll() {
    this.nestedTargets.forEach(element => {
      let destroyInput = element.querySelector("input[name*='_destroy']");
      if (destroyInput) {
        destroyInput.value = "1";
      }
      element.style.display = 'none';
    });
  }

  pullFromCurrentPage(event) {
    event.preventDefault();

    const url = this.element.dataset.url;
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const options = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json' // Ensure this is set if you are sending JSON
      },
      responseType: 'turbo-stream'
    };

    fetch(url, options).then(response => {
      response.text().then(html => {
        Turbo.renderStreamMessage(html);
      });
    }).catch(error => {
      console.error('Error:', error);
    });
  }
}
