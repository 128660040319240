import {Controller} from '@hotwired/stimulus';

import formatCurrency from '../src/format_currency';

export default class extends Controller {
  static targets = [
    'totalNetValueOutput',
    'exclusionValueInput',
    'saveButton',
    'giftOrExclusionNote'
  ];

  connect() {
    this.saveButtonTarget.disabled = true;
    let inputs = document.querySelectorAll('input');
    let closebtns = document.querySelectorAll('.modal');

    closebtns.forEach((closebtn) => {
      closebtn.addEventListener('hidden.bs.modal', () => {
        location.reload();
      });
    });

    inputs.forEach((input) => {
      input.addEventListener('input', () => {
        this.calculate();
      });
    });
    this.element.addEventListener('ajax:success', () => {
      this.calculate();
    });
    this.calculate();
  }

  calculate() {
    let radioButtons = document.querySelector('input[name="net_worth_resource[gift_or_exclusion_type]"]:checked');
    if (radioButtons) {
      if (radioButtons.value === 'other') {
        this.giftOrExclusionNoteTarget.hidden = false;
      } else {
        this.giftOrExclusionNoteTarget.hidden = true;
      }
    }
    let netWorthResourceValue =
      parseFloat(
        this.totalNetValueOutputTarget.dataset.valueBeforeExclusion
      );
    if (isNaN(netWorthResourceValue)) {
      netWorthResourceValue = 0;
    }
    let exclusionValue =
      parseFloat(
        this.exclusionValueInputTarget.value
      );
    if (isNaN(exclusionValue)) {
      exclusionValue = 0;
    }
    const netWorthResourceNetValue = netWorthResourceValue - exclusionValue;
    if (netWorthResourceNetValue < 0) {
      this.totalNetValueOutputTarget.textContent = 'Excluded amount must be equal to or less than the value';
      this.saveButtonTarget.disabled = true;
    }
    else {
      this.totalNetValueOutputTarget.textContent = formatCurrency(netWorthResourceNetValue);
    }
    if (radioButtons && netWorthResourceNetValue >= 0) {
      this.saveButtonTarget.disabled = false;
    }
  }
}
