import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['selectedTemplate'];

  connect () {};

  changeTemplate(e) {
    var templateId = this.selectedTemplateTarget.value
    var href = `/templates/${templateId}/edit` + `?case_budget_id=${e.target.dataset.caseBudgetId}`

    if (templateId === "") {
      const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const options = {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json' // Ensure this is set if you are sending JSON
        },
        responseType: 'html'
      };
      const url = `/templates/remove_template` + `?case_budget_id=${e.target.dataset.caseBudgetId}`

      fetch(url, options).then(response => {
        response.text().then(html => {
          window.location.reload();
        });
      }).catch(error => {
        console.error('Error:', error);
      })
    } else {
      e.target.href = href;
    }
  }
}
